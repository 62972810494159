import * as React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"

const theme = {
  colors: {
    bodyText: "#D4D4D4",
    link: "#989898",
    linkHover: "#D4D4D4",
    menuLink: "#787878",
    menuLinkHover: "#8EB48D",
    menuBackground: "#424242",
    containerBackground: "#424242",
    bodyBackground: "#282828",
    menuCover: "#424242",
  },
  sizes: {
    heading1: ["1.7rem", "2rem", "2.25rem"],
    heading2: ["1.5rem", "1.875rem", "1.875rem"],
    heading3: ["1.3rem", "1.5rem", "1.5rem"],
    bodyText: ["1.05rem", "1.2rem", "1.2rem"],
    lineHeight: "1.5em",
    footer: ["0.8rem", "1rem", "1rem"],
  },
  widths: {
    container: ["35em", "35em", "60em"],
  },
  heights: {
    menu: ["5em", "6.25em", "6.25em"],
  },
  margins: {
    menu: ["1em", "2em", "2.5em"],
    main: ["1em", "2em", "2.5em"],
    images: ["0.5em", "0.75em", "1em"],
    footer: ["1em", "1em", "1em"],
  },
  breakpoints: {
    tablet: "38rem",
    computer: "80rem",
  },
  fontFamilys: {
    header: `"Montserrat", sans-serif`,
    body: `"Roboto", sans-serif`,
    quote: `"Roboto Slab", serif`,
  },
}

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  body {
    margin: 0;

    font-size: 100%;
    font-family: ${props => props.theme.fontFamilys.body};
    background-color: ${props => props.theme.colors.bodyBackground};
  }

  h1,
  h2,
  h3 {
    color: ${props => props.theme.colors.bodyText};
    text-transform: uppercase;
    font-weight: normal;
    font-family: ${props => props.theme.fontFamilys.header};
    // letter-spacing: 0.1em;
  }

  h1 {
    font-size: ${props => props.theme.sizes.heading1[0]};
    @media only screen and (min-width: ${props =>
      props.theme.breakpoints.tablet}) {
      font-size: ${props => props.theme.sizes.heading1[1]};
    }
    @media only screen and (min-width: ${props =>
      props.theme.breakpoints.computer}) {
      font-size: ${props => props.theme.sizes.heading1[2]};
    }
  }

  h2 {
    font-size: ${props => props.theme.sizes.heading2[0]};
    @media only screen and (min-width: ${props =>
      props.theme.breakpoints.tablet}) {
      font-size: ${props => props.theme.sizes.heading2[1]};
    }
    @media only screen and (min-width: ${props =>
      props.theme.breakpoints.computer}) {
      font-size: ${props => props.theme.sizes.heading2[2]};
    }
  }

  h3 {
    font-size: ${props => props.theme.sizes.heading3[0]};
    @media only screen and (min-width: ${props =>
      props.theme.breakpoints.tablet}) {
      font-size: ${props => props.theme.sizes.heading3[1]};
    }
    @media only screen and (min-width: ${props =>
      props.theme.breakpoints.computer}) {
      font-size: ${props => props.theme.sizes.heading3[2]};
    }
  }

  p,
  ol li,
  ul li {
    font-size: ${props => props.theme.sizes.bodyText[0]};
    line-height: ${props => props.theme.sizes.lineHeight};
    @media only screen and (min-width: ${props =>
      props.theme.breakpoints.tablet}) {
      font-size: ${props => props.theme.sizes.bodyText[1]};
    }
    @media only screen and (min-width: ${props =>
      props.theme.breakpoints.computer}) {
      font-size: ${props => props.theme.sizes.bodyText[2]};
    }

    color: ${props => props.theme.colors.bodyText};
  }

  p {
    text-align: justify;
  }

  blockquote {
    margin-left: 22px;
    margin-right: 22px;
    p {
      font-family: ${props => props.theme.fontFamilys.quote};
      font-weight: 100;
    }
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.link};
    transition: color 0.1s;

    cursor: pointer;

    &:hover {
      color: ${props => props.theme.colors.linkHover};
    }
  }
`

const Theme = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}

export default Theme
