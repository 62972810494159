import * as React from "react"
import { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import logo from "../images/logo.svg"
import logoText from "../images/logo-text.svg"

const Menu = styled.nav`
  width: 100%;
  height: ${props => props.theme.heights.menu[0]};
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.tablet}) {
    height: ${props => props.theme.heights.menu[1]};
  }
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.computer}) {
    height: ${props => props.theme.heights.menu[2]};
  }

  background-color: ${props => props.theme.colors.menuBackground};
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.2));
  z-index: 3;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  margin: 0 ${props => props.theme.margins.menu[0]};
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.tablet}) {
    margin: 0 ${props => props.theme.margins.menu[1]};
    gap: ${props => props.theme.margins.menu[1]};
  }
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.computer}) {
    margin: 0 ${props => props.theme.margins.menu[2]};
    gap: ${props => props.theme.margins.menu[2]};
  }

  h1,
  h2 {
    line-height: 1.5em;
  }
`

// const Logo = styled(GatsbyImage)``
const Logo = styled(Link)`
  display: flex;
  align-items: center;

  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.tablet}) {
    gap: calc(${props => props.theme.margins.menu[1]} * 0.5);
  }
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.computer}) {
    gap: calc(${props => props.theme.margins.menu[2]} * 0.5);
  }
  img {
    &:first-child {
      height: calc(${props => props.theme.heights.menu[0]} * 0.8);
      @media only screen and (min-width: ${props =>
          props.theme.breakpoints.tablet}) {
        height: calc(${props => props.theme.heights.menu[1]} * 0.8);
      }
      @media only screen and (min-width: ${props =>
          props.theme.breakpoints.computer}) {
        height: calc(${props => props.theme.heights.menu[2]} * 0.8);
      }
    }
    &:last-child {
      height: calc(${props => props.theme.heights.menu[0]} * 0.4);
      display: none;
      @media only screen and (min-width: ${props =>
          props.theme.breakpoints.tablet}) {
        height: calc(${props => props.theme.heights.menu[1]} * 0.4);
        display: unset;
      }
      @media only screen and (min-width: ${props =>
          props.theme.breakpoints.computer}) {
        height: calc(${props => props.theme.heights.menu[2]} * 0.4);
      }
    }
  }
`

const Links = styled.ul`
  list-style: none;

  margin: 0;
  padding: ${props => props.theme.margins.menu[0]};
  position: absolute;
  top: calc(
    ${props => props.theme.heights.menu[0]} +
      ${props => props.theme.margins.menu[0]}
  );
  left: 0;
  transform: translateX(-100%);
  &.menuActive {
    transform: translateX(0);
  }
  transition: transform 0.5s;

  background-color: ${props => props.theme.colors.menuBackground};

  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.margins.menu[0]};
  a {
    color: ${props => props.theme.colors.menuLinkHover};
    &:hover {
      color: ${props => props.theme.colors.menuLinkHover};
    }
  }
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.tablet}) {
    top: calc(
      ${props => props.theme.heights.menu[1]} +
        ${props => props.theme.margins.menu[1]}
    );

    padding: ${props => props.theme.margins.menu[1]};
    gap: ${props => props.theme.margins.menu[1]};
  }
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.computer}) {
    position: unset;
    flex-direction: row;
    transform: translateX(0);

    padding: 0;

    gap: ${props => props.theme.margins.menu[2]};

    a {
      color: ${props => props.theme.colors.menuLink};
    }
  }

  h1 {
    margin: 0;
  }
`

const Sublinks = styled.li`
  position: relative;

  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.computer}) {
    &:hover {
      ul,
      div {
        opacity: 1;
        pointer-events: unset;
      }
    }
  }
  div {
    position: absolute;
    width: 100%;
    height: ${props => props.theme.margins.menu[2]};

    opacity: 0;
    pointer-events: none;

    display: none;
    @media only screen and (min-width: ${props =>
        props.theme.breakpoints.computer}) {
      display: unset;
    }
  }
  ul {
    list-style: none;
    a {
      color: ${props => props.theme.colors.menuLink};
    }

    padding-left: calc(${props => props.theme.margins.menu[0]} / 2);
    @media only screen and (min-width: ${props =>
        props.theme.breakpoints.tablet}) {
      padding-left: calc(${props => props.theme.margins.menu[1]} / 2);
    }
    @media only screen and (min-width: ${props =>
        props.theme.breakpoints.computer}) {
      padding: calc(${props => props.theme.margins.menu[2]} / 2);
      margin-top: ${props => props.theme.margins.menu[2]};

      opacity: 0;
      transition: opacity 0.1s;
      pointer-events: none;

      position: absolute;
      width: max-content;

      background-color: ${props => props.theme.colors.menuBackground};
    }
    li {
      &:first-child {
        h2 {
          margin-top: 0.5em;
          @media only screen and (min-width: ${props =>
              props.theme.breakpoints.computer}) {
            margin-top: 0;
          }
        }
      }
      &:last-child {
        h2 {
          margin-bottom: 0;
        }
      }
    }
  }
`

const Films = styled.ul`
  left: 0;
`

const Upcoming = styled.ul`
  right: 0;
`

const LinkButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;

  font-family: inherit;
  font-size: inherit;
  text-transform: inherit;
  padding: 0;

  color: ${props => props.theme.colors.menuLinkHover};
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.computer}) {
    color: ${props => props.theme.colors.menuLink};
  }
  &:hover {
    color: ${props => props.theme.colors.menuLinkHover};
  }
`

const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;

  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.computer}) {
    display: none;
  }

  svg {
    line {
      stroke: ${props => props.theme.colors.menuLink};
    }

    transition: stroke 0.1s;

    &:hover {
      line {
        stroke: ${props => props.theme.colors.menuLinkHover};
      }
    }
  }
`

const Cover = styled.div`
  position: absolute;
  inset: 0;
  background-color: ${props => props.theme.colors.menuCover};

  opacity: 0;
  pointer-events: none;
  &.menuActive {
    opacity: 1;
    pointer-events: unset;
  }
  transition: opacity 0.5s;

  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.computer}) {
    display: none;
  }

  z-index: 2;
`

const Header = () => {
  const {
    index: { filmsTitle, upcomingTitle },
    about: { aboutTitle },
    contact: { contactTitle },
    allContentfulFilm: { films },
    allContentfulZukunftigesProjekt: { upcoming },
    site: {
      meta: { title },
    },
  } = useStaticQuery(
    graphql`
      query {
        index: contentfulEinstellungen(
          node_locale: { eq: "de" }
          title: { eq: "Einstellungen" }
        ) {
          filmsTitle: filmsMenuName
          upcomingTitle: upcomingMenuName
        }
        about: contentfulUber(
          node_locale: { eq: "de" }
          title: { ne: "A_PH" }
        ) {
          aboutTitle: title
        }
        contact: contentfulKontakt(
          node_locale: { eq: "de" }
          title: { ne: "C_PH" }
        ) {
          contactTitle: title
        }
        allContentfulFilm(
          filter: { node_locale: { eq: "de" }, title: { ne: "F_PH" } }
          sort: { fields: releaseDate, order: DESC }
        ) {
          films: nodes {
            title
            slug
            id
          }
        }
        allContentfulZukunftigesProjekt(
          filter: { node_locale: { eq: "de" }, title: { ne: "U_PH" } }
          sort: { fields: updatedAt, order: DESC }
        ) {
          upcoming: nodes {
            title
            slug
            id
          }
        }
        site {
          meta: siteMetadata {
            title
          }
        }
      }
    `
  )

  const [menu, setMenu] = useState(false)

  return (
    <>
      <Menu>
        <Wrapper>
          <Logo to="/">
            {/* <Logo image={titleLogo.gatsbyImageData} alt={title} /> */}
            <img src={logo} alt={title} />
            <img src={logoText} alt={title} />
          </Logo>
          <Button
            onClick={() => {
              setMenu(!menu)
            }}
          >
            <svg
              width="32"
              height="24"
              viewBox="0 0 32 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ display: menu ? "none" : "unset" }}
            >
              <line
                x1="2"
                y1="2"
                x2="30"
                y2="2"
                strokeWidth="4"
                strokeLinecap="round"
              />
              <line
                x1="2"
                y1="12"
                x2="30"
                y2="12"
                strokeWidth="4"
                strokeLinecap="round"
              />
              <line
                x1="2"
                y1="22"
                x2="30"
                y2="22"
                strokeWidth="4"
                strokeLinecap="round"
              />
            </svg>
            <svg
              width="24"
              height="26"
              viewBox="0 0 24 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ display: menu ? "unset" : "none" }}
            >
              <line
                x1="2.82843"
                y1="2.70589"
                x2="21.8823"
                y2="21.7598"
                strokeWidth="4"
                strokeLinecap="round"
              />
              <line
                x1="2.11768"
                y1="22.4657"
                x2="21.1716"
                y2="3.41178"
                strokeWidth="4"
                strokeLinecap="round"
              />
            </svg>
          </Button>
          <Links className={menu ? "menuActive" : ""}>
            {films.length > 0 && (
              <Sublinks>
                <h1>
                  <LinkButton>{filmsTitle}</LinkButton>
                </h1>
                <div></div>
                <Films>
                  {films.map(film => {
                    return (
                      <li key={film.id}>
                        <h2>
                          <Link to={`/film/${film.slug}`}>{film.title}</Link>
                        </h2>
                      </li>
                    )
                  })}
                </Films>
              </Sublinks>
            )}
            {upcoming.length > 0 && (
              <Sublinks>
                <h1>
                  <LinkButton>{upcomingTitle}</LinkButton>
                </h1>
                <div></div>
                <Upcoming>
                  {upcoming.map(upcomingProject => {
                    return (
                      <li key={upcomingProject.id}>
                        <h2>
                          <Link to={`/upcoming/${upcomingProject.slug}`}>
                            {upcomingProject.title}
                          </Link>
                        </h2>
                      </li>
                    )
                  })}
                </Upcoming>
              </Sublinks>
            )}
            <li>
              <h1>
                <Link to="/about">{aboutTitle}</Link>
              </h1>
            </li>
            <li>
              <h1>
                <Link to="/contact">{contactTitle}</Link>
              </h1>
            </li>
          </Links>
        </Wrapper>
      </Menu>
      <Cover className={menu ? "menuActive" : ""}></Cover>
    </>
  )
}

export default Header
