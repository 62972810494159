import * as React from "react"
import styled from "styled-components"

import Theme from "./theme"
import Footer from "./footer"
import Header from "./header"

const Wrapper = styled.div`
  position: relative;

  min-height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const Main = styled.div`
  flex-grow: 1;

  margin: calc(${props => props.theme.margins.main[0]} * 2) 0;
  width: 100%;
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.tablet}) {
    margin: ${props => props.theme.margins.main[1]} 0;
    max-width: ${props => props.theme.widths.container[1]};
  }
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.computer}) {
    margin: ${props => props.theme.margins.main[2]} 0;
    max-width: ${props => props.theme.widths.container[2]};
  }

  main {
    background-color: ${props => props.theme.colors.containerBackground};
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.1));

    padding: ${props => props.theme.margins.main[0]} 0;
    h1,
    h2,
    h3,
    a,
    p {
      padding: 0 1rem;
    }
    ul,
    ol {
      padding: 0 ${props => props.theme.margins.main[0]} 0 25px;
    }
    @media only screen and (min-width: ${props =>
        props.theme.breakpoints.tablet}) {
      padding: ${props => props.theme.margins.main[1]};
      h1,
      h2,
      h3,
      ul,
      ol,
      a,
      p {
        padding: 0;
      }
      ul,
      ol {
        padding: 0 0 0 22px;
      }
    }
    @media only screen and (min-width: ${props =>
        props.theme.breakpoints.computer}) {
      padding: ${props => props.theme.margins.main[2]};
    }

    & > h1:first-child {
      margin-top: 0;
    }

    article {
      ul {
        list-style-type: none;

        li p:before {
          content: "";
          display: inline-block;
          height: 0.5em;
          width: 0.5em;
          background-image: url("data:image/svg+xml,%3Csvg width='8' height='11' viewBox='0 0 8 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2L5.5 5.5L2 9' stroke='%23${props => props.theme.colors.bodyText.substring(1)}' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E");
          background-size: contain;
          background-repeat: no-repeat;
          margin: 0.25em 0.2em 0 -14px;
        }
      }
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Layout = ({ children }) => {
  return (
    <Theme>
      <Wrapper>
        <Header />
        <Main>{children}</Main>
        <Footer />
      </Wrapper>
    </Theme>
  )
}

export default Layout
