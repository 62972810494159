import * as React from "react"
import styled from "styled-components"

const Logo = styled.svg`
  width: inherit;
  height: inherit;

  &:hover {
    filter: drop-shadow(0 0 3px ${props => props.color});
  }
`

const Social = ({ type, link, ...props }) => {
  if (type === "instagram")
    return (
      <a {...props} href={link} target="_blank" rel="noopener noreferrer">
        <Logo
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          color="#F00073"
        >
          <path
            d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16Z"
            fill="#F00073"
          />
          <path
            d="M16 9.20001C18.2 9.20001 18.5 9.20001 19.4 9.20001C20.2 9.20001 20.6 9.40001 20.9 9.50001C21.3 9.70001 21.6 9.80001 21.9 10.1C22.2 10.4 22.4 10.7 22.5 11.1C22.6 11.4 22.7 11.8 22.8 12.6C22.8 13.5 22.8 13.7 22.8 16C22.8 18.3 22.8 18.5 22.8 19.4C22.8 20.2 22.6 20.6 22.5 20.9C22.3 21.3 22.2 21.6 21.9 21.9C21.6 22.2 21.3 22.4 20.9 22.5C20.6 22.6 20.2 22.7 19.4 22.8C18.5 22.8 18.3 22.8 16 22.8C13.7 22.8 13.5 22.8 12.6 22.8C11.8 22.8 11.4 22.6 11.1 22.5C10.7 22.3 10.4 22.2 10.1 21.9C9.8 21.6 9.6 21.3 9.5 20.9C9.4 20.6 9.3 20.2 9.2 19.4C9.2 18.5 9.2 18.3 9.2 16C9.2 13.7 9.2 13.5 9.2 12.6C9.2 11.8 9.4 11.4 9.5 11.1C9.7 10.7 9.8 10.4 10.1 10.1C10.4 9.80001 10.7 9.60001 11.1 9.50001C11.4 9.40001 11.8 9.30001 12.6 9.20001C13.5 9.20001 13.8 9.20001 16 9.20001ZM16 7.70001C13.7 7.70001 13.5 7.70001 12.6 7.70001C11.7 7.70001 11.1 7.90001 10.6 8.10001C10.1 8.30001 9.6 8.60001 9.1 9.10001C8.6 9.60001 8.4 10 8.1 10.6C7.9 11.1 7.8 11.7 7.7 12.6C7.7 13.5 7.7 13.8 7.7 16C7.7 18.3 7.7 18.5 7.7 19.4C7.7 20.3 7.9 20.9 8.1 21.4C8.3 21.9 8.6 22.4 9.1 22.9C9.6 23.4 10 23.6 10.6 23.9C11.1 24.1 11.7 24.2 12.6 24.3C13.5 24.3 13.8 24.3 16 24.3C18.2 24.3 18.5 24.3 19.4 24.3C20.3 24.3 20.9 24.1 21.4 23.9C21.9 23.7 22.4 23.4 22.9 22.9C23.4 22.4 23.6 22 23.9 21.4C24.1 20.9 24.2 20.3 24.3 19.4C24.3 18.5 24.3 18.2 24.3 16C24.3 13.8 24.3 13.5 24.3 12.6C24.3 11.7 24.1 11.1 23.9 10.6C23.7 10.1 23.4 9.60001 22.9 9.10001C22.4 8.60001 22 8.40001 21.4 8.10001C20.9 7.90001 20.3 7.80001 19.4 7.70001C18.5 7.70001 18.3 7.70001 16 7.70001Z"
            fill="white"
          />
          <path
            d="M16 11.7C13.6 11.7 11.7 13.6 11.7 16C11.7 18.4 13.6 20.3 16 20.3C18.4 20.3 20.3 18.4 20.3 16C20.3 13.6 18.4 11.7 16 11.7ZM16 18.8C14.5 18.8 13.2 17.6 13.2 16C13.2 14.5 14.4 13.2 16 13.2C17.5 13.2 18.8 14.4 18.8 16C18.8 17.5 17.5 18.8 16 18.8Z"
            fill="white"
          />
          <path
            d="M20.4 12.6C20.9523 12.6 21.4 12.1523 21.4 11.6C21.4 11.0477 20.9523 10.6 20.4 10.6C19.8477 10.6 19.4 11.0477 19.4 11.6C19.4 12.1523 19.8477 12.6 20.4 12.6Z"
            fill="white"
          />
        </Logo>
      </a>
    )
  if (type === "vimeo")
    return (
      <a {...props} href={link} target="_blank" rel="noopener noreferrer">
        <Logo
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          color="#1AB7EA"
        >
          <path
            d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16Z"
            fill="#1AB7EA"
          />
          <path
            d="M8 12.8L8.7 13.7C8.7 13.7 10 12.6 10.5 13.2C10.9 13.7 12.7 20.1 13.2 21.3C13.7 22.3 15.1 23.7 16.6 22.7C18.1 21.7 23.1 17.5 24 12.4C24.9 7.39999 18 8.39999 17.3 12.8C19 11.7 20 13.2 19 15C18.1 16.7 17.2 17.9 16.8 17.9C16.4 17.9 16 16.7 15.5 14.7C15 12.5 15 8.69999 12.8 9.19999C10.7 9.59999 8 12.8 8 12.8Z"
            fill="white"
          />
        </Logo>
      </a>
    )
  if (type === "youtube")
    return (
      <a {...props} href={link} target="_blank" rel="noopener noreferrer">
        <Logo
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          color="#FF0000"
        >
          <path
            d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16Z"
            fill="#FF0000"
          />
          <path
            d="M23.6 12.1C23.4 11.4 22.9 10.9 22.2 10.7C21 10.4 15.9 10.4 15.9 10.4C15.9 10.4 10.9 10.4 9.60001 10.7C8.90001 10.9 8.4 11.4 8.2 12.1C8 13.4 8 16 8 16C8 16 8 18.6 8.3 19.9C8.5 20.6 9 21.1 9.7 21.3C10.9 21.6 16 21.6 16 21.6C16 21.6 21 21.6 22.3 21.3C23 21.1 23.5 20.6 23.7 19.9C24 18.6 24 16 24 16C24 16 24 13.4 23.6 12.1ZM14.4 18.4V13.6L18.6 16L14.4 18.4Z"
            fill="white"
          />
        </Logo>
      </a>
    )
  if (type === "twitter")
    return (
      <a {...props} href={link} target="_blank" rel="noopener noreferrer">
        <Logo
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          color="#1DA1F2"
        >
          <path
            d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16Z"
            fill="#1DA1F2"
          />
          <path
            d="M24 11C23.4 11.3 22.8 11.4 22.1 11.5C22.8 11.1 23.3 10.5 23.5 9.7C22.9 10.1 22.2 10.3 21.4 10.5C20.8 9.9 19.9 9.5 19 9.5C16.9 9.5 15.3 11.5 15.8 13.5C13.1 13.4 10.7 12.1 9 10.1C8.1 11.6 8.6 13.5 10 14.5C9.5 14.5 9 14.3 8.5 14.1C8.5 15.6 9.6 17 11.1 17.4C10.6 17.5 10.1 17.6 9.6 17.5C10 18.8 11.2 19.8 12.7 19.8C11.5 20.7 9.7 21.2 8 21C9.5 21.9 11.2 22.5 13 22.5C19.1 22.5 22.5 17.4 22.3 12.7C23 12.3 23.6 11.7 24 11Z"
            fill="white"
          />
        </Logo>
      </a>
    )
  if (type === "facebook")
    return (
      <a {...props} href={link} target="_blank" rel="noopener noreferrer">
        <Logo
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          color="#1877F2"
        >
          <path
            d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16Z"
            fill="#1877F2"
          />
          <path
            d="M24 16C24 11.6 20.4 8 16 8C11.6 8 8 11.6 8 16C8 20 10.9 23.3 14.7 23.9V18.3H12.7V16H14.7V14.2C14.7 12.2 15.9 11.1 17.7 11.1C18.6 11.1 19.5 11.3 19.5 11.3V13.3H18.5C17.5 13.3 17.2 13.9 17.2 14.5V16H19.4L19 18.3H17.1V24C21.1 23.4 24 20 24 16Z"
            fill="white"
          />
        </Logo>
      </a>
    )
  return
}

export default Social
