import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ description, lang, meta, image: card, title }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            defaultdescription: description
            author
            copyright
            keywords
            defaultImage: image
            siteUrl
          }
        }
      }
    `
  )

  const {
    defaultTitle,
    defaultdescription,
    author,
    copyright,
    keywords,
    defaultImage,
    siteUrl,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultdescription,
    titleTemplate: title !== defaultTitle ? `%s · ${defaultTitle}` : "%s",
    image: card || `${siteUrl}${defaultImage}`,
    canonical: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      titleTemplate={seo.titleTemplate}
      link={
        seo.canonical
          ? [
              {
                rel: "canonical",
                href: seo.canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: "description",
          content: seo.description,
        },
        {
          name: "author",
          content: author,
        },
        {
          name: "copyright",
          content: copyright,
        },
        {
          name: "keywords",
          content: keywords.join(","),
        },
        {
          property: "og:title",
          content: seo.title,
        },
        {
          property: "og:description",
          content: seo.description,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:url",
          content: seo.canonical,
        },
        {
          name: "twitter:creator",
          content: copyright,
        },
        {
          name: "twitter:title",
          content: seo.title,
        },
        {
          name: "twitter:description",
          content: seo.description,
        },
      ]
        .concat(
          seo.image
            ? [
                {
                  property: "og:image",
                  content: seo.image,
                },
                {
                  property: "twitter:image",
                  content: seo.image,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary",
                },
              ]
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: "de",
  meta: [],
  description: "",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
}

export default SEO
