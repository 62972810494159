import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Social from "./social"

const Wrapper = styled.footer`
  width: 100%;

  z-index: 1;

  margin: ${props => props.theme.margins.footer[0]};
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.tablet}) {
    margin: ${props => props.theme.margins.footer[1]};
  }
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.computer}) {
    margin: ${props => props.theme.margins.footer[2]};
  }
`

const SocialMedia = styled.div`
  display: flex;
  justify-content: center;
  gap: 1em;

  margin-bottom: ${props => props.theme.margins.footer[0]};
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.tablet}) {
    margin-bottom: ${props => props.theme.margins.footer[1]};
  }
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.computer}) {
    margin-bottom: ${props => props.theme.margins.footer[2]};
  }
`

const Links = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;

  list-style-type: none;
  padding: 0;

  margin: ${props => props.theme.margins.footer[0]}
    ${props => props.theme.margins.menu[0]} 0;
  gap: ${props => props.theme.margins.footer[0]};
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.tablet}) {
    margin: ${props => props.theme.margins.footer[1]}
      ${props => props.theme.margins.menu[1]} 0;
    gap: ${props => props.theme.margins.footer[1]};

    li {
      &:first-child,
      &:last-child {
        flex-grow: 1;
        flex-basis: 0;
      }
      &:last-child > a {
        float: right;
      }
    }
  }
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.computer}) {
    margin: ${props => props.theme.margins.footer[2]}
      ${props => props.theme.margins.menu[2]} 0;
    gap: ${props => props.theme.margins.footer[2]};
  }

  li {
    & > * {
      font-size: ${props => props.theme.sizes.footer[0]};
      @media only screen and (min-width: ${props =>
          props.theme.breakpoints.tablet}) {
        font-size: ${props => props.theme.sizes.footer[1]};
      }
      @media only screen and (min-width: ${props =>
          props.theme.breakpoints.computer}) {
        font-size: ${props => props.theme.sizes.footer[2]};
      }
    }
  }
`

const Footer = () => {
  const {
    site: { meta },
    social,
  } = useStaticQuery(
    graphql`
      query {
        site {
          meta: siteMetadata {
            copyright
          }
        }
        social: contentfulEinstellungen(
          node_locale: { eq: "de" }
          title: { eq: "Einstellungen" }
        ) {
          instagram
          vimeo
          twitter
          facebook
        }
      }
    `
  )

  return (
    <Wrapper>
      <SocialMedia>
        {Object.keys(social).map(network => {
          return (
            <React.Fragment key={network}>
              {social[network] && (
                <Social type={network} link={social[network]} />
              )}
            </React.Fragment>
          )
        })}
      </SocialMedia>
      <Links>
        <li>
          <span>
            ©{new Date().getFullYear()} {meta.copyright}
          </span>
        </li>
        <li>
          <Link to="/impressum/">Impressum</Link>
        </li>
        <li>
          <Link to="/dsgvo/">Datenschutz</Link>
        </li>
      </Links>
    </Wrapper>
  )
}

export default Footer
